<template>
    <div class="box">
        <div class="jbxx">
            <div class="jbtop">
                <div class="icon"></div>
                <div class="tit">优惠券信息</div>
            </div>
            <el-form ref="form" :model="form" label-width="150px">
              <el-row>
                <el-col :span="7">
                    <el-form-item label="优惠券名称">
                      <el-input v-model="form.couponsName"></el-input>
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                    <el-form-item label="优惠券面额">
                      <el-input v-model="form.subtractNum"></el-input>
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                    <el-form-item label="优惠券使用最低金额">
                      <el-input v-model="form.enoughNum"></el-input>
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
              <el-col :span="7">
                    <el-form-item label="领取条件" >
                      <el-radio-group style="display: flex;margin-top: 10px" v-model="radio4" @change="change4">
                        <el-radio :label="1">固定时间</el-radio>
                        <el-radio :label="2">领取之后(待更新)</el-radio>
                        <el-radio :label="3">长期有效(待更新)</el-radio>
                      </el-radio-group>
                      <!-- <el-input v-model="form.rangeCode"></el-input> -->
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                    <el-form-item label="优惠券有效期">
                      <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                        end-placeholder="结束日期"  />
                   </el-form-item>  
                </el-col>
              </el-row>
            </el-form>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">优惠券设置</div>
            </div>
            <el-form ref="form" :model="form" label-width="150px">
              <el-row>
                <el-col :span="7">
                    <el-form-item label="适用范围" >
                      <el-radio-group style="display: flex;margin-top: 10px" v-model="radio1" @change="change1">
                        <el-radio :label="1">全程通用</el-radio>
                        <el-radio :label="2">指定金刚区分类</el-radio>
                        <el-radio :label="3">指定店铺</el-radio>
                      </el-radio-group>
                      <!-- <el-input v-model="form.rangeCode"></el-input> -->
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                    <el-form-item label="金刚区适用范围" v-if="radio1==2">
                      <el-tag
                        v-for="(tag,index) in tags" :key="index"
                        style="margin-right: 5px;"
                        >
                        {{tag.categoryName}}
                      </el-tag>
                   </el-form-item>  
                   <el-form-item label="店铺适用范围" v-if="radio1==3">
                      <el-tag
                        v-for="(tag,index) in tags" :key="index"
                         style="margin-right: 5px;"
                        >
                        {{tag.businessName}}
                      </el-tag>
                   </el-form-item>  
                </el-col>
              </el-row>
              <!-- <el-row>
                <el-col :span="7">
                    <el-form-item label="是否支持点击跳转" >
                      <el-radio-group style="display: flex;margin-top: 10px" v-model="radio2">
                        <el-radio :label="1">跳转</el-radio>
                        <el-radio :label="2">不跳转</el-radio>
                      </el-radio-group>
                   </el-form-item>  
                </el-col>
              </el-row> -->
              <el-row>
                <el-col :span="7">
                    <el-form-item label="领取条件" >
                      <el-radio-group style="display: flex;margin-top: 10px" v-model="radio3" @change="change3">
                        <el-radio :label="1">全部用户</el-radio>
                        <el-radio :label="2">会员等级</el-radio>
                      </el-radio-group>
                      <!-- <el-input v-model="form.rangeCode"></el-input> -->
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="14">
                    <el-form-item label="发放总量">
                      <div style="display: flex;">
                        <el-input v-model="form.totalCount"><template slot="append">张</template></el-input>
                        <el-checkbox style="margin-left: 10px;" v-model="checkedzl" @change="zlchange">不限</el-checkbox>
                      </div>
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="14">
                    <el-form-item label="每人限领">
                    <div style="display: flex;">
                      <el-input v-model="form.oneCount" >
                      <template slot="append">张</template>
                      </el-input>
                      <el-checkbox style="margin-left: 10px;" v-model="checkedxl" @change="xlchange">不限</el-checkbox>
                     
                    </div>
                   </el-form-item>  
                   
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="14">
                    <el-form-item label="使用说明">
                      <el-input v-model="form.useIllustrate"></el-input>
                   </el-form-item>  
                </el-col>
              </el-row>
            </el-form>
            <div class="butt">
                <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button>
                <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
            </div>
        </div>
        <el-dialog title="选择金刚区" :visible.sync="userDialog" width="500px" height="400px">
      <div class="dia">
        <el-table :data="restaurants" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChange" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="categoryName" label="品类名称" align="center">
          </el-table-column>
          <el-table-column prop="creatTime" label="创建时间" align="center">
          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialog = false">取消</el-button>
          <el-button type="primary" @click="userDialog = false">确定 </el-button>
        </div>
      </div>

        </el-dialog>
        <el-dialog title="选择店铺" :visible.sync="userDialogs" width="500px" height="400px">
      <div class="dia">
        <el-table :data="restaurantss" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChanges" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="businessName" label="商家名称" align="center">
          </el-table-column>
          <el-table-column prop="creatTime" label="创建时间" align="center">
          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialogs = false">取消</el-button>
          <el-button type="primary" @click="userDialogs = false">确定 </el-button>
        </div>
      </div>

        </el-dialog>
    </div>
  </template>
  
  <script>
 export default {
 
  data() {
    return {
        radio1:0,
        radio2:0,
        radio3:0,
        radio4:0,
        checkedxl: false,
        checkedzl:false,
        //金刚区
        userDialog:false,
        restaurants:[],
        jgulist:[],
        userDialogs:false,
        restaurantss:[],
        jgulists:[],
        //店铺

        form: {
          
          
        },
        tableData:[
            
        ],
        showDialog:false,
        options: [{
          value: '1',
          label: '店长'
        }, {
          value: '2',
          label: '员工'
        }],
        value:'',
        id:'',
        valuechange:'',
        tags:[]

    };
  },
  created(){
    console.log(this.$route.query);
    this.id = this.$route.query.id
    var that = this;
    if(this.id) {
      that.$http.post("/coupons/queryOne", {
      id:that.$route.query.id
    }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
        that.form = response.data.data
        that.radio1 = response.data.data.rangeCode
        that.radio3 = response.data.data.conditions
        that.radio4 = response.data.data.expiration
        if(that.radio1==2) {
          that.tags= JSON.parse(response.data.data.designate) 
        }
        if(that.radio1==3) {
          that.tags= JSON.parse(response.data.data.designateBusiness)
        }
        
        that.valuechange = [response.data.data.startTime, response.data.data.endTime]
        }
      });
    }
    
    
      that.$http.post("/area/queryList", {}).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            // that.total = response.data.data.count;
            // that.queryInfo.currPage = response.data.data.currPage
            that.restaurants = response.data.data;
          }
      });

      that.$http.post("/business/queryBusinessList", {}).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            // that.total = response.data.data.count;
            // that.queryInfo.currPage = response.data.data.currPage
            that.restaurantss = response.data.data;
          }
        });

  },
  mounted() {
   
  },
  methods: {
    handleSuccess(response, file, fileList){
      console.log( response.data.url);
    },
    change(){
      console.log(this.valuechange);
      this.form.startTime= this.newDate(this.valuechange[0])
      this.form.endTime = this.newDate(this.valuechange[1])
    },
    newDate(time){
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    onSubmit(){
      var that = this;
      if(that.id){
        that.form.id = that.id
        that.form.designate = that.jgulist
        that.form.designateBusiness = that.jgulists
	      that.$http.post("/coupons/save", that.form).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
         console.log(response.data.data);
         that.$router.push('/coupon')
        }
      });
      } else {
        that.form.designate = that.jgulist
        that.form.designateBusiness = that.jgulists
	      that.$http.post("/coupons/add", that.form).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
         console.log(response.data.data);
         that.$router.push('/coupon')
        }
      });
      }
      
       
        
    },
    add(){
        this.showDialog = true
    },
    change1(e){
      console.log(e);
      this.form.rangeCode = e
      if(e == 1) {
        var that = this;
        that.jgulist = []
        that.jgulists = []
        
      }
      if(e == 2) {
        var that = this;
        that.userDialog = true
        that.jgulists = []
        
      }
      if(e == 3) {
        var that = this;
        that.userDialogs = true
        that.jgulist = []
       
      }
      
      

    },
    
    change3(e){
      this.form.conditions = e
    },
    change4(e){
      this.form.expiration = e
    },
    xlchange(e){
      console.log(e);
      if(e) {
        this.form.oneCount = 999999999
      }
      
    },
    zlchange(e){
      console.log(e);
      if(e) {
        this.form.totalCount = 999999999
      }
      
    },
    handleSelectionChange(val){
      console.log(val);
      this.jgulist = val
      this.tags =val
      
    },
    handleSelectionChanges(val){
      console.log(val);
      this.jgulists = val
      this.tags =val
      
    }
  }
};
  </script>
  
  <style lang="less" scoped>
  .box {
    background-color: #fff;
    padding-top: 20px;
    .jbxx {
        .jbtop {
            width: 1559px;
            height: 56px;
            background-color: #eef1f6;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-left: 10px;
            margin-bottom: 40px;
            .icon {
                width: 6px;
                height: 19px;
                border-radius: 10px;
                background-color: #f77f1f;
                margin-right: 10px;
            }
            .tit {
                font-weight: 700;
            }
        }
        
    }
    .butt {
        display: flex;
        justify-content: space-between;
        padding: 50px 300px;
    }
    .tab {
        padding: 20px;
    }
    .dia {
      .diafour {
            display: flex;
            justify-content: space-between;
            padding: 20px 230px;
        }
    }
  }
  </style>
  